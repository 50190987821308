<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ $t('PRODUCTS_LIST.TITLE_NEW') }}</h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link :to="{ name: 'productsListIndex' }" class="btn btn-secondary font-weight-bold btn-sm">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
        {{ $t('BASE.BACK') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <b-container fluid class="p-0">
        <b-row>
          <b-col cols="12" md="8" offset-md="2">
            <b-form-group id="input-group-1" :label="`${$t('FORM.NAME')}`" label-for="input-1" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-1"
                v-model="$v.form.name.$model"
                :state="validateState('name')"
                :placeholder="$t('PLACEHOLDER.NAME')"
                :disabled="!!createdProductUuid"
                trim
                @input="clearServerError('name')"
                aria-describedby="input-1-feedback"
              />
              <b-form-invalid-feedback id="input-1-feedback">
                <template v-if="serverErrors.name">{{ serverErrors.name[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.NAME') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-4" :label="`${$t('FORM.SKU')}`" label-for="input-4" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-4"
                v-model="$v.form.sku.$model"
                :state="validateState('sku')"
                :placeholder="$t('PLACEHOLDER.SKU')"
                :disabled="!!createdProductUuid"
                trim
                @input="clearServerError('sku')"
                aria-describedby="input-4-feedback"
              />
              <b-form-invalid-feedback id="input-4-feedback">
                <template v-if="serverErrors.sku">{{ serverErrors.sku[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.SKU') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-2" :label="`${$t('FORM.CATEGORY')}`" label-for="select-1" label-cols-md="2" label-align-md="right">
              <alioth-select v-model="$v.form.category.$model" repository="stock/product-categories"
                             :invalid="validateState('category') === false"
                             :disabled="!!createdProductUuid"
                             @input="clearServerError('category')"
              />
              <b-form-invalid-feedback id="select-1-feedback" :class="{'d-block': validateState('category') === false}">
                <template v-if="serverErrors.category">{{ serverErrors.category[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.CATEGORY') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-5" :label="`${$t('FORM.SUPPLIER')}`" label-for="select-2" label-cols-md="2" label-align-md="right">
              <alioth-select v-model="$v.form.supplier.$model" repository="stock/product-suppliers"
                             :invalid="validateState('supplier') === false"
                             :disabled="!!createdProductUuid"
                             @input="clearServerError('supplier')"
              />
              <b-form-invalid-feedback id="select-2-feedback" :class="{'d-block': validateState('supplier') === false}">
                <template v-if="serverErrors.supplier">{{ serverErrors.supplier[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.SUPPLIER') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-6" :label="`${$t('FORM.COST')}`" label-for="input-6" label-cols-md="2" label-align-md="right">
              <InputCurrency
                id="input-1"
                v-model="$v.price.cost.$model"
                :state="validatePriceState('cost')"
                :placeholder="$t('PLACEHOLDER.COST')"
                trim
                @input="clearServerError('cost')"
                aria-describedby="input-1-feedback"
              />
              <b-form-invalid-feedback id="input-1-feedback">
                <template v-if="serverErrors.cost">{{ serverErrors.cost[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.COST') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-7" :label="`${$t('FORM.START_DATE')}`" label-for="input-7" label-cols-md="2" label-align-md="right">
              <Datepicker id="datepicker"
                          v-model="$v.price.startDate.$model"
                          :state="validatePriceState('startDate')"
                          @input="clearServerError('startDate', 'price');clearServerError('endDate', 'price')"
                          aria-describedby="datepicker-feedback"
              />
              <b-form-invalid-feedback id="datepicker-feedback">
                <template v-if="serverErrors.startDate">{{ serverErrors.startDate[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.START_DATE') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-8" :label="`${$t('FORM.END_DATE')}`" label-for="input-8" label-cols-md="2" label-align-md="right">
              <Datepicker id="datepicker-2"
                          v-model="$v.price.endDate.$model"
                          :state="validatePriceState('endDate')"
                          :disabled="!price.startDate"
                          @input="clearServerError('endDate', 'price');clearServerError('startDate', 'price')"
                          aria-describedby="datepicker-2-feedback"
              />
              <b-form-invalid-feedback id="datepicker-2-feedback">
                <template v-if="serverErrors.endDate">{{ serverErrors.endDate[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.END_DATE') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="text-right">
              <b-button :disabled="loading || $v.form.$anyError" type="submit" variant="primary" size="sm">
                <b-spinner v-if="loading" small variant="light" />
                {{ $t('FORM.SUBMIT') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </template>
</KTCard>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Repo from '@/core/repository/company/productsRepository'
import PriceRepo from '@/core/repository/company/productsPricesRepository'
import { TIMEOUT_REDIRECT } from '@/constants/base'
import Datepicker from '@/components/forms-items/Datepicker'
import InputCurrency from '@/components/forms-items/inputCurrency'
import KTCard from '../../../../content/Card'
import InputForm from '../../../../../components/forms-items/input'
import serverVuelidate from '../../../../../mixins/serverVuelidate'
import AliothSelect from '../../../../../components/forms-items/alioth-select'

export default {
  name: 'ProductsListCreate',
  components: {
    AliothSelect,
    InputForm,
    KTCard,
    Datepicker,
    InputCurrency,
  },
  mixins: [validationMixin, serverVuelidate],
  data() {
    return {
      loading: false,
      createdProductUuid: '',
      form: {
        name: '',
        sku: '',
        supplier: null,
        category: null,
      },
      price: {
        cost: '',
        startDate: new Date(),
        endDate: '',
      },
      validations: {
        form: {
          name: { required },
          sku: { required },
          supplier: { required },
          category: { required },
        },
        price: {
          cost: { required },
          startDate: { required },
          endDate: { required },
        },
      },
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('PRODUCTS_LIST.TITLE_NEW') }])
  },
  methods: {
    resetForm() {
      this.form = {
        name: '',
        cost: '',
        sku: '',
        supplier: null,
        category: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    validatePriceState(name) {
      const { $dirty, $error } = this.$v.price[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      this.$v.form.$touch()
      this.$v.price.$touch()
      if (this.$v.form.$invalid || this.$v.price.$invalid) {
        return
      }
      this.clearServerErrors('price')
      this.loading = true

      if (this.createdProductUuid) {
        this.onCreatePrice(this.createdProductUuid)
      }

      const obj = { ...this.form }
      obj.supplier = obj.supplier ? obj.supplier.uuid : obj.supplier
      obj.category = obj.category ? obj.category.uuid : obj.category
      Repo.post(obj).then(({ data }) => {
        this.createdProductUuid = data.payload.uuid
        this.onCreatePrice(this.createdProductUuid)
      }).catch((err) => {
        this.loading = false
        this.seServerErrors(err.response.data)
      })
    },

    onCreatePrice(productUuid) {
      this.loading = true
      const payload = {
        ...this.price,
        product: productUuid,
      }

      PriceRepo.post(payload)
        .then(() => {
          this.$bvToast.toast(this.$t('TOAST.CREATED'), {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 2000,
            solid: true,
          })
          setTimeout(() => this.$router.push({
            name: 'productsListEdit',
            params: {
              id: this.createdProductUuid,
            },
          }), TIMEOUT_REDIRECT)
          this.resetForm()
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.seServerErrors(err.response.data, 'price')
        })
    },
  },
}
</script>

<style scoped>

</style>
